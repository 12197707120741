var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "guide-form-comp" },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-position": "top",
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "auto-height-item",
                      attrs: { label: "内容记录", prop: "contentRecord" },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          rows: 5,
                          maxlength: "250",
                          placeholder: "请描述具体内容",
                        },
                        model: {
                          value: _vm.ruleForm.contentRecord,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "contentRecord", $$v)
                          },
                          expression: "ruleForm.contentRecord",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 48 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "项目选择", prop: "projectId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          on: { change: _vm.subsetCompany },
                          model: {
                            value: _vm.ruleForm.projectId,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "projectId", $$v)
                            },
                            expression: "ruleForm.projectId",
                          },
                        },
                        _vm._l(_vm.projectTypeList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: {
                              value: item.projectId,
                              label: item.projectName,
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.optionUnit(item.projectName)
                              },
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "公司选择", prop: "enterpriseId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { filterable: "", placeholder: "请选择公司" },
                          model: {
                            value: _vm.ruleForm.enterpriseId,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "enterpriseId", $$v)
                            },
                            expression: "ruleForm.enterpriseId",
                          },
                        },
                        _vm._l(_vm.companyList, function (item) {
                          return _c("el-option", {
                            key: item.enterpriseId,
                            attrs: {
                              value: item.enterpriseId,
                              label: item.enterpriseName,
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.changeCompany(item.enterpriseName)
                              },
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "联系人", prop: "contacts" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "50", placeholder: "请输入联系人" },
                        model: {
                          value: _vm.ruleForm.contacts,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "contacts", $$v)
                          },
                          expression: "ruleForm.contacts",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "联系电话", prop: "telephone" } },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "50",
                          placeholder: "请输入联系电话",
                        },
                        model: {
                          value: _vm.ruleForm.telephone,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "telephone", $$v)
                          },
                          expression: "ruleForm.telephone",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "经办客服", prop: "handledPeople" } },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "50",
                          placeholder: "请输入经办客服",
                        },
                        model: {
                          value: _vm.ruleForm.handledPeople,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "handledPeople", $$v)
                          },
                          expression: "ruleForm.handledPeople",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "经办客服电话", prop: "hotlineCusPhone" },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "50",
                          placeholder: "请输入经办客服电话",
                        },
                        model: {
                          value: _vm.ruleForm.hotlineCusPhone,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "hotlineCusPhone", $$v)
                          },
                          expression: "ruleForm.hotlineCusPhone",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "问题类型", prop: "questionType" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          model: {
                            value: _vm.ruleForm.questionType,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "questionType", $$v)
                            },
                            expression: "ruleForm.questionType",
                          },
                        },
                        _vm._l(_vm.questionTypeList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: {
                              value: item.value,
                              label: item.questionName,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "什么类型事件？", prop: "orderType" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { size: "medium" },
                          on: { change: _vm.changeOrderType },
                          model: {
                            value: _vm.ruleForm.orderType,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "orderType", $$v)
                            },
                            expression: "ruleForm.orderType",
                          },
                        },
                        [
                          _c(
                            "el-radio",
                            { attrs: { border: "", label: "N" } },
                            [_vm._v("非工单事件")]
                          ),
                          _c(
                            "el-radio",
                            { attrs: { border: "", label: "Y" } },
                            [_vm._v("工单事件")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.ruleForm.orderType === "Y"
            ? _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "工单模板", prop: "orderTemplateId" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              model: {
                                value: _vm.ruleForm.orderTemplateId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "orderTemplateId", $$v)
                                },
                                expression: "ruleForm.orderTemplateId",
                              },
                            },
                            _vm._l(
                              _vm.workOrderTemplate,
                              function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    value: item.orderConfigId,
                                    label: item.configName,
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.getConfigName(item.configName)
                                    },
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { attrs: { align: "right" } },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        { name: "preventReClick", rawName: "v-preventReClick" },
                      ],
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("确定")]
                  ),
                  _c("el-button", { on: { click: _vm.resetForm } }, [
                    _vm._v("取消"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }