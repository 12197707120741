var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container invoice-record-component" },
    [
      _c("div", { staticClass: "filter-line" }, [
        _c(
          "span",
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.addNewEvent()
                  },
                },
              },
              [_vm._v(" 新增事件 ")]
            ),
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.exportHandler } },
              [_vm._v(_vm._s(this.$t("commons.export")))]
            ),
            _c(
              "el-button",
              {
                attrs: { icon: "el-icon-refresh-right" },
                on: { click: _vm.getTableList },
              },
              [_vm._v(_vm._s(this.$t("commons.refresh")))]
            ),
          ],
          1
        ),
        _c("div", { staticClass: "orderTypeBtn" }, [
          _c(
            "div",
            {
              class: _vm.requestParams.isOrderEvent == "N" ? "orderOn" : "",
              on: {
                click: function ($event) {
                  return _vm.changeOrderType("N")
                },
              },
            },
            [_vm._v("\n        非工单事件\n      ")]
          ),
          _c(
            "div",
            {
              class: _vm.requestParams.isOrderEvent == "Y" ? "orderOn" : "",
              on: {
                click: function ($event) {
                  return _vm.changeOrderType("Y")
                },
              },
            },
            [_vm._v("\n        工单事件\n      ")]
          ),
        ]),
      ]),
      _c(
        "div",
        [
          _c("finalTable", {
            ref: "finalTableRef",
            attrs: { datas: _vm.dataset },
            on: { tableEventHandler: _vm.tableEventHandler },
            scopedSlots: _vm._u([
              {
                key: "projectIdSlot",
                fn: function (row) {
                  return [
                    _c("span", [_vm._v(" " + _vm._s(row.datas.projectName))]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.detailDialogShow,
            width: "728px",
            "destroy-on-close": true,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.detailDialogShow = $event
            },
          },
        },
        [
          _c("template", { slot: "title" }, [_c("span", [_vm._v("新建事件")])]),
          _vm.detailDialogShow
            ? _c("workOrderDialog", { on: { close: _vm.closeDialog } })
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }