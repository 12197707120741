<template>
  <div class="app-container invoice-record-component">
    <div class="filter-line">
      <span>
        <el-button type="primary" @click="addNewEvent()"> 新增事件 </el-button>
        <el-button type="primary" @click="exportHandler">{{
          this.$t("commons.export")
        }}</el-button>
        <el-button @click="getTableList" icon="el-icon-refresh-right">{{
          this.$t("commons.refresh")
        }}</el-button>
      </span>

      <div class="orderTypeBtn">
        <div
          :class="requestParams.isOrderEvent == 'N' ? 'orderOn' : ''"
          @click="changeOrderType('N')"
        >
          非工单事件
        </div>
        <div
          :class="requestParams.isOrderEvent == 'Y' ? 'orderOn' : ''"
          @click="changeOrderType('Y')"
        >
          工单事件
        </div>
      </div>
    </div>
    <div>
      <finalTable ref="finalTableRef" :datas="dataset" @tableEventHandler="tableEventHandler" >
        <template v-slot:projectIdSlot="row">
          <span> {{ row.datas.projectName }}</span>
        </template>
      </finalTable>
    </div>
    <!-- 发票详情 -->
    <el-dialog
      :visible.sync="detailDialogShow"
      width="728px"
      :destroy-on-close="true"
      :close-on-click-modal="false"
    >
      <template slot="title">
        <span>新建事件</span>
      </template>
      <workOrderDialog v-if="detailDialogShow" @close="closeDialog" />
    </el-dialog>
  </div>
</template>

<script>
import finalTable from "@/components/FinalTable";
import workOrderDialog from "./workOrderDialog.vue";
import {
  getInvoiceList,
  invoiceListExport,
  cancelOrder,
} from "@/api/ruge/lego/customerSeats/workOrderEvent";
import { dateFormat } from "@/filters/index";
import { getProjectList } from "@/api/business/base/tenant/map";

export default {
  name: "gsParkWorkOrderEvent",
  components: {
    finalTable,
    workOrderDialog,
  },
  data() {
    return {
      dataset: {
        paginationConfig: {
          need: true,
        },
        filterLine: {
          show: true,
        },
        selection: {
          need: false,
        },
        header: [
          { prop: "createdTime", label: "创建时间", width: "" },
          { prop: "projectId", label: "项目名称", width: "" },
          // { prop: "positionDescName", label: "房号/单位名称", width: "" },
          { prop: "enterpriseDescName", label: "所属公司", width: "" },
          { prop: "issueType", label: "问题类型", width: "" },
          { prop: "contactPerson", label: "联系人", width: "" },
          { prop: "contactPhone", label: "联系电话", width: "" },
          { prop: "ordPersonName", label: "经办客服", width: "" },
          // { prop: "status", label: "状态", width: "" },
          { prop: "orderStatus", label: "工单状态", width: "" },
          { prop: "reviewStatus", label: "回访状态", width: "" },
          { prop: "operation", label: "操作", width: "150" },
        ],
        tableData: [],
        // 搜索行配置
        searchLineConfig: {
          projectId: {
            type: "selector",
            label: "项目名称",
            value: "",
            placeholder: "请选择项目名称",
            actionType: "goSearch",
            optionList: [],
          },
          // positionDescName: {
          //   type: "input",
          //   label: "房号/单位名称",
          //   value: "",
          //   actionType: "goSearch",
          //   placeholder: "请输入房号/单位名称",
          //   prefixIcon: "el-icon-search",
          // },
          enterpriseDescName: {
            type: "input",
            label: "所属公司",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入所属公司",
            prefixIcon: "el-icon-search",
          },
          reviewStatus: {
            type: "selector",
            label: "回访状态",
            actionType: "goSearch",
            value: "",
            placeholder: "请选择回访状态",
            optionList: [
              {
                label: "未回访",
                value: "1",
              },
              {
                label: "已回访",
                value: "2",
              },
              {
                label: "不回访",
                value: "3",
              },
            ],
          },
          status: {
            type: "selector",
            label: "处理状态",
            actionType: "goSearch",
            value: "",
            placeholder: "请选择处理状态",
            optionList: [
              {
                label: "处理中",
                value: "1",
              },
              {
                label: "已处理",
                value: "2",
              },
            ],
          },
          orderStatus: {
            type: "selector",
            label: "工单状态",
            actionType: "goSearch",
            value: "",
            placeholder: "请选择工单状态",
            optionList: [
              {
                label: "待审核",
                value: "0",
              },
              {
                label: "待领取",
                value: "1",
              },
              {
                label: "待执行",
                value: "2",
              },
              {
                label: "执行中",
                value: "3",
              },
              {
                label: "待验收",
                value: "4",
              },
              {
                label: "已完成",
                value: "5",
              },
              {
                label: "报单被驳回",
                value: "6",
              },
              {
                label: "验收被驳回",
                value: "7",
              },
              {
                label: "报单已撤销",
                value: "8",
              },
              {
                label: "已关单",
                value: "9",
              },
            ],
          },
          issueType: {
            type: "input",
            label: "问题类型",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入问题类型",
            prefixIcon: "el-icon-search",
          },
          contactPhone: {
            type: "input",
            label: "联系电话",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入联系电话",
            prefixIcon: "el-icon-search",
          },
          ordPersonName: {
            type: "input",
            label: "经办客服",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入经办客服",
            prefixIcon: "el-icon-search",
          },
          contactPerson: {
            type: "input",
            label: "联系人",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入联系人",
            prefixIcon: "el-icon-search",
          },
          operation: {
            type: "button",
            filterCount: "",
            actionType: "clickEvent",
            eventName: "showMoreFilters",
            label: "更多筛选",
          },
        },
        // 表格内容配置
        detailConfig: {
          projectId: {
            type:'slot',
            slotName: 'projectIdSlot'
          },
          createdTime: {
            type: "dateFormat",
          },
          operation: {
            type: "icon",
            iconList: [
              {
                actionType: "iconClick",
                eventName: "view",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-view",
                tooltips: "查看详情",
              },
              {
                actionType: "iconClick",
                eventName: "revoke",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-view",
                tooltips: "撤销报单",
                hideProp: "orderStatusHide",
                hideValue: "viewHide",
                hide: true,
              },
            ],
          },
        },
        // 高级搜索配置
        advanceFilterConfig: {
          createdTime: {
            type: "dateRange",
            pickerType: "date",
            label: "创建时间",
            value: [],
          },
          projectName: {
            inline: true,
            value: "",
          },
          // positionDescName: {
          //   inline: true,
          //   value: "",
          // },
          enterpriseDescName: {
            inline: true,
            value: "",
          },
          issueType: {
            inline: true,
            value: "",
          },
          contactPerson: {
            inline: true,
            value: "",
          },
          contactPhone: {
            inline: true,
            value: "",
          },
          ordPersonName: {
            inline: true,
            value: "",
          },
          reviewStatus: {
            inline: true,
            value: "",
          },
        },
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
      requestParams: {
        current: 1,
        rowCount: 10,
        // positionDescName: null,
        enterpriseDescName: null,
        contactPhone: null,
        ordPersonName: null,
        issueType: null,
        contactPerson: null,
        projectName: null,
        startTime: null,
        endTime: null,
        isOrderEvent: "Y",
      },
      detailDialogShow: false,
      loadingFlag: false,
    };
  },
  watch: {
    loadingFlag: {
      handler(val) {
        this.$refs.finalTableRef.loadingToggle(val);
      },
    },
  },
  created() {
    if (this.$route.query.goBackFlag) {
      const curSearch = sessionStorage.getItem("invoice_list_query_params");
      if (curSearch) {
        const tempObj = JSON.parse(curSearch);
        this.requestParams = { ...this.requestParams, ...tempObj };
        Object.keys(this.requestParams).forEach((item) => {
          if (this.dataset.searchLineConfig[item]) {
            this.dataset.searchLineConfig[item].value =
              this.requestParams[item];
          }
        });
        // 处理分页数据
        this.dataset.pageVO.current = tempObj.current;
        this.dataset.pageVO.rowCount = tempObj.rowCount;
      }
    } else {
      sessionStorage.removeItem("invoice_list_query_params");
    }
    this.getTableList();
    this.getProjectList()
  },
  methods: {
    getProjectList() {
      getProjectList().then(res => {
        let compantList = []
        res.forEach(item => {
          compantList.push({
            label: item.projectName,
            value: item.projectId
          })
        })
        this.dataset.searchLineConfig.projectId.optionList = compantList
      })
    },
    tbRowDetail(row) {
      console.log(row);
      let params = {
        path: "/gsPark/informationDelivery/eventregistration/detail",
        query: {
          isLook: true,
          dataId: row.id,
        },
      };
      this.$router.push(params);
    },
    closeDialog() {
      this.detailDialogShow = false;
      this.getTableList();
    },
    addNewEvent() {
      this.detailDialogShow = true;
    },
    changeOrderType(type) {
      if (type == this.requestParams.isOrderEvent) return;
      this.orderStatusToggle(type); //非工单事件隐藏撤销报单
      this.requestParams.isOrderEvent = type;
      this.chengeStructure();
      this.getTableList();
    },
    orderStatusToggle(type) {
      this.dataset.detailConfig.operation.iconList.forEach((item) => {
        if (item.eventName == "revoke") {
          item.hide = type === "N";
        }
      });
    },
    tableEventHandler(datas) {
      console.log("datas", datas);
      if (datas.type === "goSearch") {
        this.requestParams.current = 1;
        this.dataset.pageVO.current = 1;
        this.dealTime(datas);
        this.requestParams = { ...this.requestParams, ...datas.params };
        this.getTableList();
      } else if (datas.type === "paginationChange") {
        this.dataset.pageVO.current = datas.params.current.page;
        this.requestParams.current = datas.params.current.page;
        this.requestParams.rowCount = datas.params.current.limit;
        this.getTableList();
      } else if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "view":
            this.tbRowDetail(datas.row);
            break;
          case "revoke":
            this.revokeOrder(datas.row);
            break;
        }
      }
    },
    revokeOrder(row) {
      this.$confirm("您确认撤销报单吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          cancelOrder({
            orderId: row.orderId,
          }).then((res) => {
            if (res.code === 200) {
              this.$message.success("撤销成功！");
              this.getTableList();
            } else {
              this.$message.warning("撤销失败！");
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    dealTime(datas) {
      if (datas.params.createdTime && datas.params.createdTime.length > 0) {
        this.requestParams.startTime = datas.params.createdTime[0]
          ? dateFormat(datas.params.createdTime[0], "YYYY-MM-DD HH:mm:ss")
          : null;
        this.requestParams.endTime = datas.params.createdTime[1]
          ? dateFormat(
              new Date(datas.params.createdTime[1]).getTime() +
                1000 * 60 * 60 * 24 -
                1,
              "YYYY-MM-DD HH:mm:ss"
            )
          : null;
        delete datas.params.createdTime;
      }
      // else {
      //   this.requestParams.startTime = null;
      //   this.requestParams.endTime = null;
      // }
      return datas;
    },
    chengeStructure() {
      this.dataset.header.forEach((item) => {
        if (item.prop == "status" && this.requestParams.isOrderEvent == "Y") {
          item.prop = "orderStatus";
          item.label = "工单状态";
        } else if (
          item.prop == "orderStatus" &&
          this.requestParams.isOrderEvent == "N"
        ) {
          item.prop = "status";
          item.label = "处理状态";
        }
      });
      let newObj = {
        inline: true,
        value: "",
      };
      // if (this.requestParams.isOrderEvent == "Y") {
      //   this.$set(this.dataset.advanceFilterConfig, "orderStatus", newObj);
      //   this.$delete(this.dataset.advanceFilterConfig, "status");
      // } else if (this.requestParams.isOrderEvent == "N") {
      //   this.$set(this.dataset.advanceFilterConfig, "status", newObj);
      //   this.$delete(this.dataset.advanceFilterConfig, "orderStatus");
      // }
    },
    getTableList() {
      // this.chengeStructure();
      this.loadingFlag = true;
      getInvoiceList(this.requestParams)
        .then((res) => {
          res.rows.forEach((item) => {
            item.status = item.status == 1 ? "处理中" : "已处理";
            if (item.reviewStatus == 1) {
              item.reviewStatus = "未回访";
            } else if (item.reviewStatus == 2) {
              item.reviewStatus = "已回访";
            } else {
              item.reviewStatus = "不回访";
            }

            switch (item.orderStatus) {
              case 0:
                item.orderStatus = "待审核";
                this.$set(item, "orderStatusHide", "showHide");
                break;
              case 1:
                item.orderStatus = "待领取";
                break;
              case 2:
                item.orderStatus = "待执行";
                break;
              case 3:
                item.orderStatus = "执行中";
                break;
              case 4:
                item.orderStatus = "待验收";
                break;
              case 5:
                item.orderStatus = "已完成";
                break;
              case 6:
                item.orderStatus = "报单被驳回";
                break;
              case 7:
                item.orderStatus = "验收被驳回";
                break;
              case 8:
                item.orderStatus = "报单已撤销";
                break;
              case 9:
                item.orderStatus = "已完成";
                break;
            }
            //区分工单状态显示撤销操作
            this.$set(
              item,
              "orderStatusHide",
              item.orderStatus == "待审核" ? "viewShow" : "viewHide"
            );
          });
          this.dataset.tableData = res.rows;
          this.dataset.pageVO.total = res.total;
        })
        .catch(() => {
          this.dataset.tableData = [];
          this.dataset.pageVO.total = 0;
        })
        .finally(() => {
          this.loadingFlag = false;
        });
    },
    exportHandler() {
      invoiceListExport(this.requestParams).then((taskId) => {
        this.$message({
          type: "success",
          message: this.$t("message.operationSuccess"),
        });
        let exportObj = {
          taskId,
          taskName:
            this.requestParams.isOrderEvent == "Y"
              ? "工单事件列表"
              : "非工单事件列表",
          taskStatus: 0,
          rootPath: "customerGSPath",
        };
        this.$store.dispatch("PushExportNotice", exportObj);
      });
    },
    // 查看详情
    tbRowDetail(row) {
      console.log(row);
      let params = {
        path: "/lego/customerSeats/workOrderEvent/detail",
        query: {
          isOrder: this.requestParams.isOrderEvent,
          dataId: row.id,
        },
      };
      this.$router.push(params);
    },
  },
};
</script>

<style lang="less" scoped>
.invoice-record-component {
  .filter-line {
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      height: 36px;
    }
    .icon-loudoutu {
      font-size: 12px;
    }
    .header-filter-button {
      cursor: pointer;
      width: 206px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 3px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
    }
    .filter-button-open {
      color: #409eff;
      background: #ecf5ff;
      border: 1px solid #c6e2ff;
    }
    .filter-button-close {
      color: #2a4158;
      background: #ffffff;
      border: 1px solid #e7e8eb;
    }
  }
  ::v-deep .el-dialog__body {
    padding-top: 0 !important;
    padding-bottom: 20px !important;
  }
  .orderTypeBtn {
    display: flex;
    div {
      margin-left: 10px;
      text-align: center;
      padding: 0 18px;
      height: 36px;
      line-height: 36px;
      background: #ffffff;
      border-radius: 3px 3px 3px 3px;
      border: 1px solid #4374fa;
      font-size: 14px;
      font-family: Noto Sans SC, Noto Sans SC;
      font-weight: 400;
      color: #303133;
      border: 1px solid #dcdfe5;
    }
    .orderOn {
      border: 1px solid #4374fa;
      background: #f4f6fe;
      color: #4374fa;
    }
    &:hover {
      cursor: pointer;
    }
  }
}
</style>