import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

/**
 * 分页查询
 * @param params
 */
export function getInvoiceList(params) {
  return request({
    url: envInfo.bgApp.customerlegoPath + '/record/queryList',
    method: 'get',
    params,
  });
}

/**
 * 导出
 * @param params
 */
export function invoiceListExport(params) {
  return request({
    url: envInfo.bgApp.customerlegoPath + '/record/export',
    method: 'get',
    params,
  });
}
/**
 * 获取项目列表
 * @param params
 */
export function getProjectList(params) {
  return request({
    url: envInfo.bgApp.basePath + '/tenant/project/list',
    method: 'get',
    params,
  });
}

/**
 * 获取子集位置
 * @param params
 */
export function getSubsetPosition(params) {
  return request({
    url: envInfo.bgApp.basePath + '/tenant/position/selectPositionTree',
    method: 'get',
    params,
  });
}

/**
 * 获取工单模板
 * @param params
 */
export function getOrderTemplate(params) {
  return request({
    url: envInfo.bgApp.customerlegoPath + '/record/getOrderTemplateList',
    method: 'get',
    params,
  });
}

/**
 * 新增工单
 * @param params
 */
export function addWorkOrder(params) {
  return request({
    url: envInfo.bgApp.customerlegoPath + '/record/create',
    method: 'post',
    data: params,
  });
}

/**
 * 获取工单模板
 * @param params
 */
export function getOrderDetail(params) {
  return request({
    url: envInfo.bgApp.customerlegoPath + '/record/getDetailById',
    method: 'get',
    params,
  });
}

/**
 * 保存非工单事件处理结果
 * @param params
 */
export function createHandle(params) {
  return request({
    url: envInfo.bgApp.customerlegoPath + '/record/createHandle',
    method: 'post',
    data: params,
  });
}

/**
 * 保存回访满意度
 * @param params
 */
export function createReview(params) {
  return request({
    url: envInfo.bgApp.customerlegoPath + '/record/createReview',
    method: 'post',
    data: params,
  });
}

/**
 * 修改回访记录状态
 * @param params
 */
export function updateReviewStatus(params) {
  return request({
    url: envInfo.bgApp.customerlegoPath + '/record/updateReviewStatus',
    method: 'get',
    params,
  });
}

/**
 * 撤销报单
 * @param params
 */
export function cancelOrder(params) {
  return request({
    url: envInfo.bgApp.customerlegoPath + '/record/cancelOrder',
    method: 'get',
    params,
  });
}

/**
 * 公司查询
 * @param params
 */
export function getCompany(params) {
  return request({
    url: envInfo.bgApp.orgPath + '/enterprise/list',
    method: 'get',
    params,
  });
}
